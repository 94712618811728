import React from "react"
import Link from "./Link"

import Helmet from "react-helmet"

import pic01 from '../assets/images/pic01.jpg'

import Slideshow, { HalfSlideshow } from "./Slideshow"
import { useScreen } from "../lib/hooks"

// const isMobile = typeof window !== 'undefined' ? window.screen.width <= 980 : false

const backgroundTextColor = ( background, tint = globalTint ) => background && tint > 0 ? { color: background && 'white', fontWeight: 500 } : {}
const globalTint = 0.1

export const  Button = ({ url, text, external, style: buttonStyle, buttonId, special, onClick, sectionStyle }) => {
    if( !url ){
        return <li key={text}><button onClick={onClick} className={`button${special || sectionStyle === "style2" ? " special" : ""}${buttonStyle ? ' ' + buttonStyle : ''}`}>{text}</button></li>
    }

    if( !url.includes("http"))
        return <li key={url}><Link id={buttonId || ""} onClick={onClick} to={url} className={`button${special || sectionStyle === "style2" ? " special" : ""}${buttonStyle ? ' ' + buttonStyle : ''}`}>{text}</Link></li>

    return <li key={url}><a id={buttonId || ""} href={url} className={`button${special || sectionStyle === "style2" ? " special" : ""}${buttonStyle ? ' ' + buttonStyle : ''}`}>{text}</a></li>

}

// Section with left and right column
export const DoubleSection = ( { css, id, style = "style1", title = "Title", description = "Description goes here", opacity, image = pic01, alt, buttons, children, background, tint } ) => {

    const isMobile = useScreen()
    
    return !alt || isMobile ? (

        <section data-aos="fade-up" id={id} className={`main ${style}`} style={css}>
            {background && <Slideshow opacity={opacity} images={background} enableTint tint={tint - 0.15 || globalTint} />}
                        
            <div className="grid-wrapper">
                <div className="col-6">
                    <header className="major">
                        <h2 style={backgroundTextColor( background )}>{title}</h2>
                    </header>
                    <p style={backgroundTextColor( background )} dangerouslySetInnerHTML={{ __html: description.replace( /\n/g, "<br />" ) }} />
                    <ul className="actions uniform">
                        {buttons && buttons.map( ( button ) => {
                            return <Button {...button} sectionStyle={style} />
                        } ) }
                    </ul>
                </div>
                {children}
            </div>
        </section>
    ) 
    :
    (
        <section data-aos="fade-up" id={id} className={`main ${style}`}>
            {background && <Slideshow opacity={opacity} images={background} enableTint tint={tint - 0.15 || globalTint} />}
            <div className="grid-wrapper">
                <div className="col-6">
                    {children}
                </div>
                <div className="col-6">
                    <header className="major">
                        <h2 style={backgroundTextColor( background )}>{title}</h2>
                    </header>
                    <p style={backgroundTextColor( background )} dangerouslySetInnerHTML={{ __html: description.replace( /\n/g, "<br />" ) }} />
                    <ul className="actions uniform">
                        {buttons && buttons.map( ( button ) => {
                            return <Button {...button} sectionStyle={style} />
                        } ) }
                    </ul>
                </div>
            </div>
        </section>
    )
}

const SmallSection = ( { id, style = "style1", title = "Title", description = "Description goes here", opacity, image = pic01, alt, buttons, roundedImage, background, tint } ) => {
    const isMobile = useScreen()
    if( !isMobile ){
    return !alt ? (

        <section id={id} className={`main ${style}`}>
            {background && <Slideshow opacity={opacity} images={background} enableTint tint={tint - 0.15 || globalTint} />}
            <div className="grid-wrapper" data-aos="fade-up">
                <div className="col-6">
                    <header className="major">
                        <h2 style={backgroundTextColor( background )}>{title}</h2>
                    </header>
                    <p style={backgroundTextColor( background )} dangerouslySetInnerHTML={{ __html: description.replace( /\n/g, "<br />" ) }} />
                    <ul className="actions uniform">
                        {buttons && buttons.map( ( button ) => {
                            return <Button {...button} sectionStyle={style} />
                        } ) }
                    </ul>
                </div>
                <div  className="col-6">
                    <span data-aos="zoom-in" className={`image fit${roundedImage ? " rounded" : "" }`}><img src={image} alt="" /></span>
                </div>
            </div>
        </section>
    ) 
    :
    (
        <section id={id} className={`main ${style}`}>
            {background && <Slideshow opacity={opacity} images={background} enableTint tint={tint - 0.15 || globalTint} />}
            <div className="grid-wrapper" data-aos="fade-up">
                <div className="col-6">
                    <span data-aos="zoom-in" className={`image fit${roundedImage ? " rounded" : "" }`}><img src={image} alt="" /></span>
                </div>
                <div className="col-6 alt">
                    <header className="major">
                        <h2 style={backgroundTextColor( background )}>{title}</h2>
                    </header>
                    <p style={backgroundTextColor( background )} dangerouslySetInnerHTML={{ __html: description.replace( /\n/g, "<br />" ) }} />
                    <ul className="actions uniform">
                        {buttons && buttons.map( ( button ) => {
                            return <Button {...button} sectionStyle={style} />
                        } ) }
                    </ul>
                </div>
            </div>
        </section>
    )
    }else{
        return (
            <section id={id} className={`main ${style}`}>
                {background && <Slideshow opacity={opacity} images={background} enableTint tint={tint - 0.15 || globalTint} />}
                <div className="grid-wrapper" data-aos="fade-up">
                   
                    <div className="col-12">
                        <header className="major">
                            <h2 style={backgroundTextColor( background )}>{title}</h2>
                        </header>
                        <p style={backgroundTextColor( background )} dangerouslySetInnerHTML={{ __html: description.replace( /\n/g, "<br />" ) }} />
                        
                        <span data-aos="zoom-in" className={`image fit${roundedImage ? " rounded" : "" }`}><img src={image} alt="" /></span>
                        
                        <ul className="actions uniform">
                            {buttons && buttons.map( ( button ) => {
                                return <Button {...button} sectionStyle={style} />
                            } ) }
                        </ul>
                    </div>
                </div>
            </section>
        )
    }
}





export const BackgroundSection = ( { id, children, style = "style1", title = "Title", description = "Description goes here", 
    opacity, blur, image = pic01, alt, buttons, roundedImage, background, tint } ) => {

    const isMobile = useScreen()
    if( !isMobile ){
    return !alt ? (

        <section id={id} className={`main ${style} large`}>
            {background && <Slideshow opacity={opacity} images={background} enableTint tint={tint - 0.15 || globalTint - 0.4} blur={blur} />}
            <div className="grid-wrapper" data-aos="fade-up" data-aos-duration='800'>
                <div className="col-7">
                    <header className="major">
                        <h2 style={backgroundTextColor( background )}>{title}</h2>
                    </header>
                    <p style={backgroundTextColor( background )} dangerouslySetInnerHTML={{ __html: description.replace( /\n/g, "<br />" ) }} />
                    <ul className="actions uniform">
                        {buttons && buttons.map( ( button ) => {
                            return <Button {...button} sectionStyle={style} />
                        } ) }
                    </ul>
                </div>
                <div  className="col-5">
                    {/* <span data-aos="zoom-in" className={`image fit${roundedImage ? " rounded" : "" }`}><img src={image} alt="" /></span> */}
                </div>
                {children}
            </div>
            
        </section>
    ) 
    :
    (
        <section id={id} className={`main ${style} large`}>
            {background && <Slideshow opacity={opacity} images={background} enableTint tint={tint - 0.15 || globalTint - 0.4} blur={blur} />}
            <div className="grid-wrapper" data-aos="fade-up" data-aos-duration='800'>
                <div className="col-5">
                    {/* <span data-aos="zoom-in" className={`image fit${roundedImage ? " rounded" : "" }`}><img src={image} alt="" /></span> */}
                </div>
                <div className="col-7 alt">
                    <header className="major">
                        <h2 style={backgroundTextColor( background )}>{title}</h2>
                    </header>
                    <p style={backgroundTextColor( background )} dangerouslySetInnerHTML={{ __html: description.replace( /\n/g, "<br />" ) }} />
                    <ul className="actions uniform">
                        {buttons && buttons.map( ( button ) => {
                            return <Button {...button} sectionStyle={style} />
                        } ) }
                    </ul>
                </div>
                {children}
            </div>
            
        </section>
    )
    }else{
        return (
            <section id={id} className={`main ${style} large`}>
                {background && <Slideshow opacity={opacity} images={background} enableTint tint={tint - 0.15 || globalTint - 0.4} blur={blur} />}
                <div className="grid-wrapper" data-aos="fade-up" data-aos-duration='800'>
                   
                    <div className="col-12">
                        <header className="major">
                            <h2 style={backgroundTextColor( background )}>{title}</h2>
                        </header>
                        <p style={backgroundTextColor( background )} dangerouslySetInnerHTML={{ __html: description.replace( /\n/g, "<br />" ) }} />
                        
                        {/* <span data-aos="zoom-in" className={`image fit${roundedImage ? " rounded" : "" }`}><img src={image} alt="" /></span> */}
                        <ul className="actions uniform">
                            {buttons && buttons.map( ( button ) => {
                                return <Button {...button} sectionStyle={style} />
                            } ) }
                        </ul>
                    </div>
                    {children}
                </div>
                
            </section>
        )
    }
}



export const HalfBackgroundSection = ( { id, children, style = "style1", title = "Title", description = "Description goes here", 
    opacity, blur, image = pic01, alt, buttons, roundedImage, background, tint } ) => {

    const isMobile = useScreen()
    if( !isMobile ){
    return !alt ? (

        <section id={id} className={`main ${style} large`}>
            {background && <HalfSlideshow opacity={opacity} images={background} enableTint tint={tint - 0.15 || globalTint - 0.4} blur={blur} left={false} isMobile={isMobile}/>}
            <div className="grid-wrapper" data-aos="fade-up" data-aos-duration='800'>
                <div className="col-6">
                    <header className="major">
                        <h2>{title}</h2>
                    </header>
                    <p dangerouslySetInnerHTML={{ __html: description.replace( /\n/g, "<br />" ) }} />
                    <ul className="actions uniform">
                        {buttons && buttons.map( ( button ) => {
                            return <Button {...button} sectionStyle={style} />
                        } ) }
                    </ul>
                    {children}
                </div>
                <div  className="col-6">
                    {/* <span data-aos="zoom-in" className={`image fit${roundedImage ? " rounded" : "" }`}><img src={image} alt="" /></span> */}
                </div>
                {/* {children} */}
            </div>
            
        </section>
    ) 
    :
    (
        <section id={id} className={`main ${style} large`}>
            {background && <HalfSlideshow opacity={opacity} images={background} enableTint tint={tint - 0.15 || globalTint - 0.4} blur={blur} isMobile={isMobile}/>}
            <div className="grid-wrapper" data-aos="fade-up" data-aos-duration='800'>
                <div className="col-6">
                    {/* <span data-aos="zoom-in" className={`image fit${roundedImage ? " rounded" : "" }`}><img src={image} alt="" /></span> */}
                </div>
                <div className="col-6 alt">
                    <header className="major">
                        <h2>{title}</h2>
                    </header>
                    <p dangerouslySetInnerHTML={{ __html: description.replace( /\n/g, "<br />" ) }} />
                    <ul className="actions uniform">
                        {buttons && buttons.map( ( button ) => {
                            return <Button {...button} sectionStyle={style} />
                        } ) }
                    </ul>
                    {children}
                </div>
                {/* {children} */}
            </div>
            
        </section>
    )
    }else{
        return (
            <section id={id} className={`main ${style} large`}>
                {background && <HalfSlideshow opacity={opacity} images={background} enableTint tint={tint - 0.15 || globalTint - 0.4} blur={blur} isMobile={isMobile}/>}
                <div className="grid-wrapper" data-aos="fade-up" data-aos-duration='800'>
                   
                    <div className="col-12">
                        <header className="major">
                            <h2 style={backgroundTextColor( background, tint )}>{title}</h2>
                        </header>
                        <p style={backgroundTextColor( background, tint )} dangerouslySetInnerHTML={{ __html: description.replace( /\n/g, "<br />" ) }} />
                        
                        {/* <span data-aos="zoom-in" className={`image fit${roundedImage ? " rounded" : "" }`}><img src={image} alt="" /></span> */}
                        <ul className="actions uniform">
                            {buttons && buttons.map( ( button ) => {
                                return <Button {...button} sectionStyle={style} />
                            } ) }
                        </ul>
                    </div>
                    {children}
                </div>
                
            </section>
        )
    }
}



export const WelcomeSection = ( { id, children, style = "style1", title = "Title", description = "Description goes here", opacity, image = pic01, alt, buttons, roundedImage, background, tint } ) => {

    const isMobile = useScreen()
    if( !isMobile ){
    return !alt ? (

        <section id={id} className={`main ${style}`}>
            {background && <Slideshow opacity={opacity} images={background} enableTint tint={tint - 0.15 || globalTint} />}
            <div className="grid-wrapper" data-aos="fade-up">
                <div className="col-5">
                    <header className="major small">
                        <h2 style={backgroundTextColor( background, tint )}>{title}</h2>
                    </header>
                    <p style={backgroundTextColor( background, tint )} dangerouslySetInnerHTML={{ __html: description.replace( /\n/g, "<br />" ) }} />
                    <ul className="actions uniform">
                        {buttons && buttons.map( ( button ) => {
                            return <Button {...button} sectionStyle={style} />
                        } ) }
                    </ul>
                </div>
                <div  className="col-7">
                    {children}
                </div>
                
            </div>
            
        </section>
    ) 
    :
    (
        <section id={id} className={`main ${style}`}>
            {background && <Slideshow opacity={opacity} images={background} enableTint tint={tint - 0.15 || globalTint} />}
            <div className="grid-wrapper" data-aos="fade-up">
                <div className="col-5">
                {children}{/* <span data-aos="zoom-in" className={`image fit${roundedImage ? " rounded" : "" }`}><img src={image} alt="" /></span> */}
                </div>
                <div className="col-7 alt">
                    <header className="major">
                        <h2 style={backgroundTextColor( background, tint )}>{title}</h2>
                    </header>
                    <p style={backgroundTextColor( background, tint )} dangerouslySetInnerHTML={{ __html: description.replace( /\n/g, "<br />" ) }} />
                    <ul className="actions uniform">
                        {buttons && buttons.map( ( button ) => {
                            return <Button {...button} sectionStyle={style} />
                        } ) }
                    </ul>
                </div>
            </div>
            
        </section>
    )
    }else{
        return (
            <section id={id} className={`main ${style}`}>
                {background && <Slideshow opacity={opacity} images={background} enableTint tint={tint - 0.15 || globalTint} />}
                <div className="grid-wrapper" data-aos="fade-up">
                   
                    <div className="col-12">
                        <header className="major">
                            <h2 style={backgroundTextColor( background, tint )}>{title}</h2>
                        </header>
                        <p style={backgroundTextColor( background, tint )} dangerouslySetInnerHTML={{ __html: description.replace( /\n/g, "<br />" ) }} />
                        
                        {children} {/* <span data-aos="zoom-in" className={`image fit${roundedImage ? " rounded" : "" }`}><img src={image} alt="" /></span> */}
                        
                        <ul className="actions uniform">
                            {buttons && buttons.map( ( button ) => {
                                return <Button {...button} sectionStyle={style} />
                            } ) }
                        </ul>
                    </div>
                </div>
                
            </section>
        )
    }
}



export const TextSection = ( { id, style = "style1", title = "", description = "Description goes here", buttons, background, opacity, tint, blur, extraCSS={} } ) => {


    return (
        <section id={id} className={`main ${style}`} style={extraCSS}>
            {background && <Slideshow opacity={opacity} images={background} enableTint tint={tint - 0.15 || globalTint} blur={blur} />}
            <div className="container" data-aos="fade-up">
                <header className="major">
                    <h2 style={backgroundTextColor( background )}>{title}</h2>
                </header>
                <p style={backgroundTextColor( background )} dangerouslySetInnerHTML={{ __html: description.replace( /\n/g, "<br />" ) }} />
                <ul className="actions uniform">
                    {buttons && buttons.map( ( button ) => {
                        return <Button {...button} sectionStyle={style} special={background == null}/>
                    } ) }
                </ul>
            </div>
        </section>
    )
}

export const CardSection = ( { id, style = "style1", title = "Title", description = "Description goes here", opacity, cards, button, background, tint } ) => {

    return (
        <section id={id} className={`main ${style} special`}>
            {background && <Slideshow opacity={opacity} images={background} enableTint tint={tint - 0.15 || globalTint} />}
            <div className="grid-wrapper">
                <div className="col-12">
                    <header className="major">
                        <h2 style={backgroundTextColor( background )}>{title}</h2>
                    </header>
                </div>

                {cards.map( ( { title, image, description, url, roundedImage } ) => url ?
                    <Link key={title} onClick={onClick} to={url}>
                        <div className="col-4">
                            <span className={`image fit card ${roundedImage ? " rounded" : "" }`}><img src={image} alt="" /></span>
                            <h3>{title}</h3>
                            <p style={backgroundTextColor( background )} dangerouslySetInnerHTML={{ __html: description.replace( /\n/g, "<br />" ) }} />
                        </div>
                    </Link>
                    : 
                    <div key={title} className="col-4">
                        <span className={`image fit card ${roundedImage ? " rounded" : "" }`}><img src={image} alt="" /></span>
                        <h3>{title}</h3>
                        <p style={backgroundTextColor( background )} dangerouslySetInnerHTML={{ __html: description.replace( /\n/g, "<br />" ) }} />
                    </div>
                )}

                { button && <div className="col-12 actions"><Link to={button.url} className="button special">{button.label}</Link></div>}

            </div>
        </section>
    )
}

export const ContainerSection = ( { id, style = "style1", children, background, tint, className, blur, opacity } ) => {

    return (
        <section  id={id} className={`main ${style} special${className ? ' '+ className : ''}`}>
            {background && <Slideshow opacity={opacity} images={background} enableTint tint={tint - 0.15 || globalTint} blur={blur} />}
            <div data-aos="fade-up" className="container">
                <div className="grid-wrapper">
                    <div className="col-12">
                        {children}
                    </div>
                </div>
            </div>
        </section>
    )
}



// // Section with left and right column
// export const HalfBackgroundSection = ( { 
//     css, id, style="style1", title="Title", 
//     description = "Description goes here",
//     opacity, image = pic01, alt, buttons, 
//     children, background, tint } ) => {

//     const isMobile = useScreen()
    
//     return !alt || isMobile ? (

//         <section data-aos="fade-up" id={id} className={`main ${style}`} style={css}>                        
//             <div className="grid-wrapper" style={{width: '100%', height: '100% !important'}}>
//                 <div className="col-6">
//                     {background && <image className="about-image half left" opacity={opacity} src={background} enableTint />}
//                 </div>
//                 <div className="col-6 padded">
//                     <header className="major">
//                         <h2 style={backgroundTextColor( background, 0 )}>{title}</h2>
//                     </header>
//                     <div style={backgroundTextColor( background, 0 )}>
//                         {children}
//                     </div>
//                     <ul className="actions uniform">
//                         {buttons && buttons.map( ( button ) => {
//                             return <Button {...button} sectionStyle={style} />
//                         } ) }
//                     </ul>
//                 </div>
//             </div>
//         </section>
//     ) 
//     :
//     (
//         <section data-aos="fade-up" id={id} className={`main ${style}`}>
//             <div className="grid-wrapper" style={{width: '100%', height: '100% !important'}}>
//                 <div className="col-6">
//                     <header className="major">
//                         <h2 style={backgroundTextColor( background, 0 )}>{title}</h2>
//                     </header>
//                     <div style={backgroundTextColor( background, 0 )}>
//                         {children}
//                     </div>
//                     <ul className="actions uniform">
//                         {buttons && buttons.map( ( button ) => {
//                             return <Button {...button} sectionStyle={style} />
//                         } ) }
//                     </ul>
//                 </div>
//                 <div className="col-6">
//                     {background && <Slideshow className="slideshow-image half right" opacity={opacity} images={background} enableTint tint={0} />}
                   
//                 </div>
//             </div>
//         </section>
//     )
// }


export const HalfCardSection = ({children, title, image, left=true, className, breakpoint=992}) => {
    const isMobile = useScreen(breakpoint)
    const imageCSS = {backgroundImage: `url(${image})`, backgroundSize: 'cover', minHeight: '80vh'}
    const divCSS = {textAlign: 'center', display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%'}

    let imageClass = className ? `col-6 ${className}` : 'col-6'
    let divClass = className ? `col-6 ${className} padded` : 'col-6 padded'

    if(isMobile){
        imageClass = className ? `col-12 ${className}` : 'col-12'
        divClass = className ? `col-12 ${className} padded` : 'col-12 padded'
        return (
            <>
                <div className={imageClass}  style={imageCSS} />
                <div className={divClass}>
                    <header className="major">
                        <h2>{title}</h2>
                    </header>
                    <div>
                        {children}
                    </div>
                </div>
            </>
        )
    }
    else if(left == true){
        return (
        <>
            <div className={imageClass} style={imageCSS} />
            <div className={divClass} style={{...divCSS, backgroundColor: 'rgba(0, 55, 100, 0.1)'}}>
                <header className="major">
                    <h2>{title}</h2>
                </header>
                <div>
                    {children}
                </div>
            </div>
        </>
        )
    }else{
        return (
            <>
                <div className={divClass} style={{textAlign: 'center'}}>
                    <header className="major">
                        <h2>{title}</h2>
                    </header>
                    <div>
                       {children}
                    </div>
                </div>
                <div className={imageClass} style={imageCSS} />
            </>
        )
    }
}



export const HalfTextSection = ({children, className, extraCSS={}}) => {
    const isMobile = useScreen()
    let textClass = className ? `col-6 ${className} padded` : 'col-6 padded'
    const textCSS = {minHeight: '30vh', textAlign: 'center', ...extraCSS}


    if(isMobile){
        className = className ? `col-12 ${className} padded` : 'col-12 padded'
        return (
            <div className={textClass} style={textCSS}>
                {children}
            </div>
        )
    } else {
        return (
            <div className={textClass} style={textCSS}>
                {children}
            </div>
        )
    }
}

export default SmallSection