import React, { useState, useEffect } from 'react'


const Slideshow = ( { images = [], className = "slideshow-image", slideTimer = 5000, tint = 0, blur = 0, opacity = 1 } ) => {

    const [ imageIndex, setImageIndex ] = useState( 0 )

    const isImageActive = ( index ) => index === imageIndex


    const imageClass = ( index ) => isImageActive( index ) ? `${className} active-slide-image` : `${className} inactive-slide-image`

    useEffect( () => {
        const timer = setTimeout( () => {

            setImageIndex( images.length ? ( imageIndex + 1 )  % images.length : 0 )

        }, slideTimer )

        return () => clearTimeout( timer )
    }, [ images, imageIndex ] )

     
    return (
        <>
            
            {images && images.map( ( image, index ) => <div key={index} className={imageClass( index )} style={image && { backgroundImage : `url(${image})`, filter: `opacity(${opacity})` }} /> )}
            {blur > 0 && <span style={{content: "", top: 0, left: 0, width: '100%', height: '100%', position: 'absolute', backdropFilter:  `blur(${blur}em)` }}/> }
            {tint > 0 && <div className="slideshow-tint" style={{ opacity: tint }}/> }
        </>
    )
}


export const HalfSlideshow = ( { images = [], className = "slideshow-image", slideTimer = 5000, tint = 0, blur = 0, opacity = 1, left = true, isMobile } ) => {

    const [ imageIndex, setImageIndex ] = useState( 0 )

    const isImageActive = ( index ) => index === imageIndex

    const imageClass = ( index ) => isImageActive( index ) ? `${className} active-slide-image` : `${className} inactive-slide-image`

    useEffect( () => {
        const timer = setTimeout( () => {

            setImageIndex( images.length ? ( imageIndex + 1 )  % images.length : 0 )

        }, slideTimer )

        return () => clearTimeout( timer )
    }, [ images, imageIndex ] )

     
    return (
        <>
            
            {images && images.map( ( image, index ) => <div key={index} className={imageClass( index )} style={image && { backgroundImage : `url(${image})`, filter: `opacity(${opacity})`, left: 0, width: '100%' }} /> )}
            {!isMobile && <span className="slideshow-text-section" style={{content: "", top: 0, left: !left ? 0 : '50%', width: '50%', height: '100%', position: 'absolute'}}/>}
            {blur > 0 && <span style={{content: "", top: 0, left: left ? 0 : '50%', width: !isMobile ? '50%': '100%', height: '100%', position: 'absolute', backdropFilter:  `blur(${blur}em)` }}/> }
            {tint > 0 && <div className="slideshow-tint" style={{ left: left ? 0 : '50%', width: !isMobile ? '50%': '100%', opacity: tint }}/> }
        </>
    )
}

export default Slideshow